import Container from '../../UI/Container/Container';
import TextGenerate from '../TextGenerate/TextGenerate';
import styles from './TopSection.module.css';

const TopSection = ({ authState }) => {
	return (
		<div
			className={styles.topsection}
		>
			<Container>
				<h1 className={styles.main__title}>
					Turn Your Fantasies into Images with <b>RedRocket AI</b>
				</h1>
				<p className={styles.main__text}>
					RedRocket uses the lastest technology to generate pornographic
					images with AI. Describe your fantasies and RedRocket AI will do
					the rest.
				</p>
				<TextGenerate authState={authState} />
			</Container>
		</div>
	);
};

export default TopSection;
