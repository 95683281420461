import cuid from "cuid";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from './Dropzone.module.css'

function Dropzone({ onDrop, accept, open, setImages }) {


   useEffect(() => {

      function pasteHandler(evt) {
         const clipboardItems = evt.clipboardData.items;
         const items = [].slice.call(clipboardItems).filter(function (item) {
             // Filter the image items only
             return /^image\//.test(item.type);
         });
         if (items.length === 0) {
             return;
         }

         const item = items[0];
         const blob = item.getAsFile();

         acceptedFiles.push(blob)

         acceptedFiles.map(file => {
            const reader = new FileReader();
            
            reader.onload = function (e) {
               const img = new Image();
               img.onload = function() {
                  const imgWidth = this.width;
                  const imgHeight = this.height;
                  setImages(prevState => [
                     ...prevState,
                     { id: cuid(), src: e.target.result, width: imgWidth, height: imgHeight},
                  ]);
               }
               img.src = e.target.result
               
               
            };
            
            reader.readAsDataURL(file);
            return file;
         });
      } 

      document.addEventListener('paste', pasteHandler)

      return () => document.removeEventListener('paste', pasteHandler)
   }, [])

   const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
     useDropzone({
      accept: {
         'image/png': ['.png'], 
         'image/jpeg': ['.jpg', '.jpeg'] 
       },
       onDrop,
     });
   const files = acceptedFiles.map((file) => (
     <li key={file.path}>
       {file.path} - {file.size} bytes
     </li>
   ));
   return (
     <div>
       <div {...getRootProps({ className: styles.dropzone + (isDragActive ? ' ' + styles.dropzone__active : '') })}>
         <input {...getInputProps()} />
         <div>
            <button type="button" onClick={open} className={styles.dropzone__btn}>
             Upload Image
           </button>
             <div className={styles.dropzone__texts}>
               <p className={styles['dropzone__texts-drop']}>or drag and drop images</p>
               <p className={styles['dropzone__texts-paste']}>Paste image or Ctrl + V</p>
             </div>
         </div>
       </div>
     </div>
   );
 }

export default Dropzone;