import { Link } from 'react-router-dom'
import styles from './HeaderMob.module.css'

import closeIcon from '../../../assets/img/closeIcon.png'

const HeaderMob = props => {

   const logoutMobHandler = e => {
      props.setIsMobMenuOpen(false)
      props.logoutHandler()
   }

   return <nav className={`${styles.nav} ${props.isMobMenuOpen ? styles.active : ''}`}>
      <button className={styles.close} onClick={e => props.setIsMobMenuOpen(false)}>
         <img src={closeIcon} alt="close" />
      </button>
      <ul className={styles.list}>
         <li className={styles.item}>
            <Link onClick={() => props.setIsMobMenuOpen(false)} className={styles['link']} to="/pricing">Pricing</Link>
         </li>
         <li className={styles.item}>
            <Link onClick={() => props.setIsMobMenuOpen(false)} className={styles['link']} to="/support">Support</Link>
         </li>
         {props.authState.isAuth ? 
            <>
               <li className={styles.item}>
                  <Link onClick={() => props.setIsMobMenuOpen(false)} className={styles['link']} to="/saved">Saved</Link>
               </li>
               <li className={styles.item}>
                  <button onClick={logoutMobHandler} className={`${styles['logout']} ${styles['link']}`}>Logout</button>
               </li>
            </>
            :
            <li className={styles.item}>
               <Link onClick={() => props.setIsMobMenuOpen(false)} className={styles['link']} to="/login">Login</Link>
            </li>
            
         }
      </ul>
   </nav>
}

export default HeaderMob