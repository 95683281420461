import cuid from "cuid";
import { useCallback, useEffect, useState } from 'react';
import Dropzone from '../../UI/Dropzone/Dropzone';
import ImageGenerateCrop from '../ImageGenerateCrop/ImageGenerateCrop';
import ImageGeneratePreview from '../ImageGeneratePreview/ImageGeneratePreview';
import ImageGenerateResult from '../ImageGenerateResult/ImageGenerateResult';
import ImageGeneratorOnlyForPremium from '../ImageGeneratorOnlyForPremium/ImageGeneratorOnlyForPremium';
import ImageLoader from '../ImageLoader/ImageLoader';

const ImageGenerateDragAndDrop = props => {
   
	const [images, setImages] = useState([]);
   const [isUploadStep, setIsUploadStep] = useState('dropzone')

   const [progressLoader, setProgressLoader] = useState(0);
   const [serverResponse, setServerResponse] = useState();

   const [imagesCropped, setImagesCropped] = useState([]);



   useEffect(() => {
      if (isUploadStep === 'dropzone') {
         setImages([])
      }
   }, [isUploadStep])

	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.map(file => {
			const reader = new FileReader();
         
			reader.onload = function (e) {
            const img = new Image();
            img.onload = function() {
               const imgWidth = this.width;
               const imgHeight = this.height;
               setImages(prevState => [
                  ...prevState,
                  { id: cuid(), src: e.target.result, width: imgWidth, height: imgHeight},
               ]);
            }
            img.src = e.target.result
				
            
			};
         
			reader.readAsDataURL(file);
			return file;
		});
	}, []);

   useEffect(() => {
      if (images.length) {
         if (!props.authState.isAuth) {
            setIsUploadStep('needpremium')
         } else {
            setIsUploadStep('crop')
         }
      }
   }, [images])


	return (
		<div>
         {(isUploadStep === 'dropzone') ? <Dropzone setImages={setImages} onDrop={onDrop} accept={"image/*"}/> : ''}
         {(isUploadStep === 'crop') ? <ImageGenerateCrop setImagesCropped={setImagesCropped} setIsUploadStep={setIsUploadStep} images={images}/> : ''}
         {(isUploadStep === 'paint') ? <ImageGeneratePreview progressLoader={progressLoader} setProgressLoader={setProgressLoader} images={imagesCropped} setIsUploadStep={setIsUploadStep} setServerResponse={setServerResponse}/> : ''}
         {(isUploadStep === 'loader') ? <ImageLoader progressLoader={progressLoader} /> : ''}
         {(isUploadStep === 'final') ? <ImageGenerateResult setIsUploadStep={setIsUploadStep} resultSrc={serverResponse} /> : ''}

         {(isUploadStep === 'needpremium') ? <ImageGeneratorOnlyForPremium /> : ''}
		</div>
	);
};

export default ImageGenerateDragAndDrop;
