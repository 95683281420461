import { Link } from 'react-router-dom'
import styles from './ImageGeneratorOnlyForPremium.module.css'

const ImageGeneratorOnlyForPremium = props => {
   return <div className={styles.premium}>
      <p className={styles.premium__text}>
      This feature is for premium users! <br/>
      Check out our <Link to="/pricing" className={styles.premium__link}>pricing plans</Link>
      </p>
   </div>
}

export default ImageGeneratorOnlyForPremium