import styles from './Title.module.css'

const Title = props => {
   if (props.size === 'h1') {
      return <h1 className={styles.title}>{props.children}</h1>
   } else if (props.size === 'h2') {
      return <h2 className={styles.title}>{props.children}</h2>
   }
}

export default Title