import Container from '../../UI/Container/Container';
import styles from './HowItWorks.module.css';

const HowItWorks = props => {
	return (
		<section className={styles.howitworks}>
			<Container>
				<h2 className={styles.howitworks__title}>
					<b>How</b> It Works
				</h2>
				<p className={styles.howitworks__text}>
					Our image generating AI is designed to be user-friendly and
					accessible to everyone. No matter your level of experience or
					expertise, you can easily create amazing images with our tool.
					Simply enter your prompt and let our AI do the rest – it's as
					easy as that!
				</p>
			</Container>
		</section>
	);
};

export default HowItWorks;
