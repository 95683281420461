import { useNavigate } from 'react-router-dom'
import Container from '../../UI/Container/Container'
import GradientButton from '../../UI/GradientButton/GradientButton'
import styles from './TheBestDreams.module.css'

const TheBestDreams = props => {

   const push = useNavigate()

   const btnHandler = e => {
      e.preventDefault()
      push('/pricing')
   }

   return <section className={styles.dreams}>
      <Container>
         <h2 className={styles.dreams__title}>The best <b>dreams</b> of our community members</h2>
         <div className={styles.dreams__inner}>
            <img className={styles.dreams__img} src="/dream1.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream2.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream3.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream4.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream5.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream6.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream7.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream8.png" alt="image dream" />
            <img className={styles.dreams__img} src="/dream9.png" alt="image dream" />
         </div>
         <GradientButton onClick={btnHandler} className={styles.dreams__btn} text="Join Now 25% Off"/>
         <p className={styles.dreams__subtext}>Starting at only $0.05 an image</p>
      </Container>
      <img className={styles.trectangle} src="/trectangle2.svg" alt="trectangle shape" />
   </section>
}

export default TheBestDreams