import { Link } from 'react-router-dom'
import Container from '../../UI/Container/Container'
import styles from './TheBestNudes.module.css'

const TheBestNudes = props => {
   return <section className={styles.thebest}>
      <Container>
         <h2 className={styles.thebest__title}>The best <b>NUDES</b> of our community members</h2>
         <div className={styles.thebest__inner}>
            <span className={styles.thebest__subtitle}>Before</span>
            <span className={styles.thebest__subtitle}>AFTER</span>
            <img src="/best1.png" alt="best" className={styles.thebest__img} />
            <img src="/best2.png" alt="best" className={styles.thebest__img} />
            <img src="/best3.png" alt="best" className={styles.thebest__img} />
            <img src="/best4.png" alt="best" className={styles.thebest__img} />
            <img src="/best5.png" alt="best" className={styles.thebest__img} />
            <img src="/best6.png" alt="best" className={styles.thebest__img} />
         </div>
         <Link className={styles.btn} to="/pricing">Join Now 25% Off</Link>
         <p className={styles.subtext}>Starting at only $0.05 an image</p>
      </Container>
      <img className={styles.trectangle} src="/trectangle2.svg" alt="trectangle shape" />
   </section>
}

export default TheBestNudes