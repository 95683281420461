import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Title from '../UI/Title/Title'
import AIGeneratedGallery from './Gallery/AIGeneratedGallery'
import NudifyGallery from './Gallery/NudifyGallery'
import styles from './SavedPage.module.css'

const SavedPage = props => {

   const [activeTab, setIsActiveTab] = useState('aiGenerated')
   
   const navigate = useNavigate()

   useEffect(() => {
      console.log(props.authState.isAuth)
      if (!props.authState.isAuth) {
         navigate('/login')
      }
   }, [])

   return <section className={styles.saved}>
      <div className={styles.container}>
         <Title size="h2">Saved images</Title>
         <div className={styles.saved__toggle}>
            <button onClick={() => setIsActiveTab('aiGenerated')} className={`${styles['saved__toggle-btn']} ${activeTab === 'aiGenerated' ? styles['active'] : ''}`}><span>AI Generated</span></button>
            <button onClick={() => setIsActiveTab('nudify')} className={`${styles['saved__toggle-btn']} ${activeTab === 'nudify' ? styles['active'] : ''}`}><span>Nudify</span></button>
            <div className={`${styles['saved__active-btn']} ${activeTab === 'aiGenerated' ? styles['left'] : styles['right']}`}></div>
         </div>
         {(activeTab === 'aiGenerated') ? <AIGeneratedGallery /> : <NudifyGallery /> }
         <button className={styles.saved__loadmore}>Load more</button>
      </div>
   </section>
}

 export default SavedPage