import Container from '../../UI/Container/Container'
import styles from './MainAdvantage.module.css'

const MainAdvantage = props => {
   return <section className={styles.advantage}>
      <Container>
         <div className={styles.advantage__inner}>
            <div className={styles.advantage__item}>
               <span className={styles.advantage__num}>1</span>
               <p className={styles.advantage__text}>
                  Discord is a FREE, easy to join community platform with 300 million users. Our image generation app is currently only available here.
               </p>
            </div>
            <div className={styles.advantage__item}>
               <span className={styles.advantage__num}>2</span>
               <p className={styles.advantage__text}>
                  After you get verified on Discord, go to a rookie channel and type a prompt starting with "/imagine: prompt" followed by text describing the image you want.
               </p>
            </div>
            <div className={styles.advantage__item}>
               <span className={styles.advantage__num}>3</span>
               <p className={styles.advantage__text}>
                  In less than a minute you’ll receive four images to download or refine further. Use these any way you wish.
               </p>
            </div>
         </div>
      </Container>
   </section>
}  

export default MainAdvantage