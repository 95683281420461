import styles from './InstructionBlock.module.css'

const InstructionBlock = props => {
   return <div className={styles.instruction}>
      <div className={styles.instruction__item}>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/600/800" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Zoom out to show majority of body, facing straight. Use a high resolution photo that has been resized to at least 1024px width.
            </p>
         </div>
      </div>
      <div className={styles.instruction__item}>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/600/800" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Avoid baggy clothing and coats. Single piece dresses are OK. Tighter clothing have better results.
            </p>
         </div>
      </div>
      <div className={styles.instruction__item}>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/600/800" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Good contrast between skin and clothing colors. Hair not blocking too much of body.
            </p>
         </div>
      </div>
   </div>
}

export default InstructionBlock