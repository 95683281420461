import styles from './GradientButton.module.css';

const GradientButton = props => {
	return (
		<button
			onClick={props.onClick}
			className={`${styles.button} ${props.className}`}
		>
			{props.text}
		</button>
	);
};

export default GradientButton;
