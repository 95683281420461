import { CSSTransition } from 'react-transition-group'
import styles from './Modal.module.css'
import ReactDOM from 'react-dom';

const Modal = props => {

   const closeHandler = e => {
      e.stopPropagation();
   }

   return <CSSTransition
      unmountOnExit
      mountOnEnter
      in={props.stateValue}
      timeout={props.timeout}
      classNames='modal__animation'
   >
   <div className={styles.modal__wrapper} onClick={() => props.closeFunction(false)}>
         <div className={styles.modal} onClick={e => e.stopPropagation()}>
            {props.children}
         </div>
      </div>
   </CSSTransition> 
}

const ModalWithPortal = props => {
   return ReactDOM.createPortal(<Modal closeFunction={props.closeFunction} timeout={props.timeout} stateValue={props.stateValue} >{props.children}</Modal>, document.querySelector('#modal'))
}

export default ModalWithPortal