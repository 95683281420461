import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { payment } from '../../api/api';
import GradientButton from '../UI/GradientButton/GradientButton';
import styles from './PurchasePage.module.css';

import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PurchasePage = props => {

	const { planId } = useParams();

   const {imageCount, price, planName} = props.pricing.find(item => item.planName.replace(' ', '-').toLowerCase() === planId)

   const [purchasePrice, setPurchasePrice] = useState(price)
   const [countNumber, setCountNumber] = useState(1)

   useEffect(() => {
      setPurchasePrice((countNumber * price).toFixed(2))
   }, [countNumber])

   const decrementHandler = e => {
      if (countNumber - 1 > 0) {
         setCountNumber(countNumber - 1)
      }
   }

   const incrementHandler = e => {
      setCountNumber(countNumber + 1)
   }

   const authFailed = () => {
      toast.error('First log in or register', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const serverError = () => {
      toast.error('Ops.. Something is wrong', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const paymentHandler = e => {
      e.preventDefault()

      if (!props.authState.authInfo.login || !props.authState.authInfo.password || !props.authState.authInfo.token) {
         authFailed()
      }

      payment.paypal(`${planName}, x${countNumber}`, props.authState.authInfo.login, purchasePrice, props.authState.authInfo.token).then(data => {
         
         window.location.href = data.payment_url
         console.log(data)
      }).catch(err => {
         console.log(err)
         serverError()
      })
   }

	return (
		<div className={styles.purchase}>
			<div className={styles.container}>
            <div className={styles.purchase__inner}>
               <section className={styles.purchase__main}>
                  <p className={styles.purchase__header}>
                     {imageCount} Images RedRocket [v1.5]
                  </p>
                  <div className={styles.purchase__info}>
                     <p className={styles.purchase__text}>
                        Debit/Credit Card payment (instant): Login details will be
                        sent to your email instantly with automated delivery.
                     </p>
                     <p className={styles.purchase__text}>
                        Bitcoin/Cryptocurrency Payment (~30 minuets): 2
                        confirmations is needed.
                     </p>
                     <p className={styles.purchase__text}>
                        Cashapp payments (manual, up 24 hours): Credit/Debit card
                        is a better payment option as some transactions fail due to
                        being blocked by cashapp. If you make a payment and
                        included your order ID correctly in the memo but the order
                        is still not marked as complete, this means we need to
                        manually accept the payment. Please wait up to 24 hours for
                        us to manually accept the payment.
                     </p>
                     <p className={styles.purchase__text}>
                        If your transaction is automatically refunded or failed, we
                        will send you a request for the exact failed amount. Pay
                        the request. If the request still fails, please try a
                        different payment method.
                     </p>
                     <p className={styles.purchase__text}>
                        微信/wechat: (24小时之内): 购买请发邮件至
                        wechat.dngg@gmail.com This is a 1 time purchase
                        non-recurring.
                     </p>
                  </div>
               </section>
               <aside className={styles.purchase__aside}>
                  <div className={styles['purchase__aside-inner']}>
                     <p className={styles.purchase__header}>
                        About
                     </p>
                     <div className={styles.purchase__asidemain}>
                        <span className={styles.purchase__price}>${purchasePrice}</span>
                        <GradientButton onClick={paymentHandler} text="Purchase" className={styles.purchase__buy} />
                        <div className={styles.counter}>
                           <button onClick={decrementHandler} className={styles.counter__btn}>-</button>
                           <span className={styles.counter__value}>{countNumber}</span>
                           <button onClick={incrementHandler} className={styles.counter__btn}>+</button>
                        </div>
                        <button className={styles.purchase__coupon}>Apply a Coupon</button>
                     </div>
                  </div>
                  <Link to="/support" className={styles.purchase__link}>Contact Seller</Link>
               </aside>
            </div>
			</div>
		</div>
	);
};

export default PurchasePage;
