import styles from './ModalTextGenerateButton.module.css'

import repeatIcon from '../../../../assets/img/repeatIcon.png'
import enchanceIcon from '../../../../assets/img/enchanceIcon.png'
import { useState } from 'react'

const ModalTextGenerateButton = props => {

   const [isRepeatDisable, setRepeatDisable] = useState(false)

   const repeatHandlerClick = e => {
      props.generateFromText(props.textRequest).then(() => setRepeatDisable(false))
      setRepeatDisable(true)
   }

   return <div className={styles['button__inner']}>
      <button onClick={repeatHandlerClick} className={styles.button__item + ' ' + styles.blue} disabled={isRepeatDisable}>
         <img src={repeatIcon} alt="Repeat" className={styles.button__icon} />
         <span className={styles.button__text}>Repeat</span>
      </button>
      <button className={styles.button__item + ' ' + styles.pink}>
         <img src={enchanceIcon} alt="Enhance" className={styles.button__icon} />
         <span className={styles.button__text}>Enhance</span>
      </button>
      <button className={styles.button__item + ' ' + styles.save}>
         <img src={repeatIcon} alt="Save" className={styles.button__icon} />
         <span className={styles.button__text}>Save</span>
      </button>
      <a href={props.imageBase64} className={styles.button__item + ' ' + styles.download} download={`${props.textRequest.replaceAll(' ', '-')}.jpg`}>
         <img src={enchanceIcon} alt="Download" className={styles.button__icon} />
         <span className={styles.button__text}>Download</span>
      </a>
   </div>
}

export default ModalTextGenerateButton