import Title from '../UI/Title/Title'
import PriceItem from './PriceItem/PriceItem'
import PricingFaq from './PricingFaq/PricingFaq'
import styles from './PricingPage.module.css'

const PricingPage = props => {


   const pricePlans = props.pricing.map((item, index) => <PriceItem key={index} popular={item.popular} planName={item.planName} price={item.price} priceForImg={item.priceForImg} feature={item.feature}/>)

   return <div className={styles.pricing}>
      <div className={styles.container}>
         <section className={styles.pricing__plan}>
            <Title size="h1">Pricing</Title>
            <div className={styles.pricing__price}>
               {pricePlans}
            </div>
         </section>
         <PricingFaq />
      </div>
   </div>
}

export default PricingPage