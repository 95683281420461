import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MainPage from './components/MainPage/MainPage';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import PricingPage from './components/PricingPage/PricingPage';
import SavedPage from './components/SavedPage/SavedPage';
import PurchasePage from './components/PurchasePage/PurchasePage';
import LoginPage from './components/AuthPages/LoginPage';
import RegisterPage from './components/AuthPages/RegisterPage';

function App() {

   const [authState, setAuthState] = useState({
      isAuth: false,
      authInfo: {
         login: null,
         password: null,
         token: null,
      },
      subscribeInfo: {
         plan: null,
         remainingGenerations: null,
      },
      savedLists: {
         aiGenerated: [],
         nudify: []
      }
   })

   const pricing = [
      {
         popular: false,
         planName: 'Standard',
         price: 29.95,
         priceForImg: 0.30,
         imageCount: 100,
         feature: [
            `100 image Credits`,
            'Full Results & No Queue',
            'Buy as many as you want',
         ]
      },
      {
         popular: true,
         planName: 'Standard Plus',
         price: 49.95,
         priceForImg: 0.20,
         imageCount: 200,
         feature: [
            `200 image Credits`,
            'Full Results & No Queue',
            'Buy as many as you want',
         ]
      },
      {
         popular: false,
         planName: 'Premium',
         price: 99.95,
         priceForImg: 0.10,
         imageCount: 300,
         feature: [
            `300 image Credits`,
            'Full Results & No Queue',
            'Buy as many as you want',
         ]
      },
   ]

   useEffect(() => {
      if (localStorage.getItem('auth')) {
         const authFromLocalStorage = JSON.parse(localStorage.getItem('auth'))
         setAuthState(prevState => ({
            ...prevState,
            isAuth: true,
            authInfo: {
               login: authFromLocalStorage.login,
               password: authFromLocalStorage.password,
               token: authFromLocalStorage.token
            }
         }))
      }
   }, [])

	return (
		<>
			<Header authState={authState} setAuthState={setAuthState} />
			<main className="main">
				<Routes>
					<Route path="/" element={<MainPage authState={authState} />} />
					<Route path="/pricing" element={<PricingPage pricing={pricing}/>} />
					<Route path="/saved" element={<SavedPage authState={authState} />} />
					<Route path="/purchase/:planId" element={<PurchasePage pricing={pricing} authState={authState}/>} />
					<Route path="/login" element={<LoginPage authState={authState} setAuthState={setAuthState} />} />
					<Route path="/register" element={<RegisterPage authState={authState} setAuthState={setAuthState} />} />
				</Routes>
            <ToastContainer />
			</main>
			<Footer />
		</>
	);
}

export default App;
