import styles from './ImageLoader.module.css'

const ImageLoader = props => {
   return <div className={styles.loader}>
      <span className={styles.loader__text}>Processing...</span>
      <div className={styles.loader__animation}>
         <div className={styles.meter + ' ' + styles.animate}>
            <span style={{width: `${props.progressLoader}%`}}><span></span></span>
         </div>
         <span className={styles.loader__percent}>{props.progressLoader}%</span>
      </div>
   </div> 
}

export default ImageLoader