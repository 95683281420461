import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = props => {
   return <footer className={styles.footer}>
      <div className={styles.footer__inner}>
         <div className={styles.footer__logo}>
            <h2 className={styles['footer__logo-text']}>RedRocket</h2>
            <span className={styles['footer__logo-copy']}>© 2023</span>
         </div>
         <nav className={styles.footer__menu}>
            <h3 className={styles['footer__menu-title']}>Navigation</h3>
            <ul className={styles['footer__menu-list']}>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/">Upload</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/pricing">Pricing</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/terms-of-service">Terms of Service</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/privacy-policy">Privacy Policy</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/2257">2257</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/refunds-policy">Refunds Policy</Link>
               </li>
               <li className={styles['footer__menu-item']}>
                  <Link className={styles['footer__menu-link']} to="/contact">Contact</Link>
               </li>
            </ul>
         </nav>
      </div>
   </footer>
}

export default Footer