import { Link } from 'react-router-dom'
import Container from '../../UI/Container/Container'
import Title from '../../UI/Title/Title'
import ImageGenerateDragAndDrop from '../ImageGenerateDragAndDrop/ImageGenerateDragAndDrop'
import styles from './ImageGenerate.module.css'

const ImageGenerate = props => {
   return <section className={styles.imagegenerate}>
      <img className={styles.trectangle} src="/trectangle.svg" alt="trectangle shape" />
      <Container>
         <ImageGenerateDragAndDrop authState={props.authState}/>
      </Container>
   </section>
}

export default ImageGenerate