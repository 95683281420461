import Container from '../../UI/Container/Container'
import styles from './VideoSection.module.css'
import ReactPlayer from 'react-player'

const VideoSection = ({reversed}) => {
   return <section className={styles.videosection}>
      <Container>
         <div className={`${styles.videosection__inner} ${reversed ? styles.reversed : ''}`}>
            <div className={styles.videosection__video}>
               <ReactPlayer width='100%' height='100%' url='https://youtu.be/LXb3EKWsInQ' controls={true}/>
            </div>
            <div className={styles.videosection__info}>
               <h2 className={styles['videosection__info-title']}>
                  <b>Unleash</b> your creativity
               </h2>
               <p className={styles['videosection__info-text']}>
                  BlueWillow AI can take any prompt and covert it to art. Give it a try!
               </p>
               <ul className={styles['videosection__info-list']}>
                  <li className={styles['videosection__info-list-item']}>
                     Anime
                  </li>
                  <li className={styles['videosection__info-list-item']}>
                     Blowjob
                  </li>
                  <li className={styles['videosection__info-list-item']}>
                     Trans
                  </li>
                  <li className={styles['videosection__info-list-item']}>
                     Any your dream
                  </li>
               </ul>
            </div>
         </div>
      </Container>
      <img className={styles.trectangle} src="/trectangle.svg" alt="trectangle shape" />
   </section>
}

export default VideoSection