import styles from './MainPage.module.css'
import TextGenerate from './TextGenerate/TextGenerate'
import topHomeImg from '../../assets/img/topHomeImg.png'
import ImageGenerate from './ImageGenerate/ImageGenerate'
import Title from '../UI/Title/Title'
import InstructionBlock from './InstructionBlock/InstructionBlock'
import TopSection from './TopSection/TopSection'
import Container from '../UI/Container/Container'
import TryForFree from './TryForFree/TryForFree'
import HowItWorks from './HowItWorks/HowItWorks'
import VideoSection from './VideoSection/VideoSection'
import MainAdvantage from './MainAdvantage/MainAdvantage'
import TheBestDreams from './TheBestDreams/TheBestDreams'
import TheBestNudes from './TheBestNudes/TheBestNudes'

const MainPage = props => {

   return <section className={styles.home}>
      <TopSection authState={props.authState} />
      <Container>
         <InstructionBlock />
      </Container>
      <ImageGenerate authState={props.authState} />
      <TryForFree />
      <HowItWorks />
      <VideoSection />
      <MainAdvantage />
      <TheBestDreams />
      <VideoSection reversed={true} />
      <TheBestNudes />
   </section>
}

export default MainPage