import Container from '../../UI/Container/Container'
import styles from './TryForFree.module.css'

const TryForFree = props => {
   return <section className={styles.tryforfree}>
      <Container>
         <div className={styles.tryforfree__wrapper}>
            <div className={styles.tryforfree__inner}>
               <div className={styles.tryforfree__item}>
                  <img src="/trytofree1.png" alt="trytofree1" className={styles.tryforfree__img} />
               </div>
               <div className={styles.tryforfree__item}>
                  <img src="/trytofree1.png" alt="trytofree2" className={styles.tryforfree__img} />
               </div>
               <div className={styles.tryforfree__item}>
                  <img src="/trytofree1.png" alt="trytofree3" className={styles.tryforfree__img} />
               </div>
            </div>
            <h2 className={styles.tryforfree__title}>Try for <b>free</b> NOW</h2>
            <a target="_blank" href="https://discord.com" className={styles.tryforfree__btn}>
               <img src="/discord.svg" alt="discord" />
               Try for free in Discord
            </a>
         </div>
      </Container>
      <img className={styles.trectangle} src="/trectangle2.svg" alt="trectangle shape" />
   </section>
}

export default TryForFree