import { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './ImageGenerateCrop.module.css';





const ImageGenerateCrop = props => {

   function getCroppedImg(image, crop) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
      );

      return canvas.toDataURL('image/jpeg')
   }
  
   
   const [crop, setCrop] = useState()

   console.log(crop)

   const cropHandler = e => {
      
      props.setImagesCropped([
         {...props.images[0], src: getCroppedImg(imageRef.current, crop)}
      ])
      props.setIsUploadStep('paint')
   }

   const imageRef = useRef()

   return <div className={styles.result}>
      <p className={styles.result__text}>
         Crop your image in square shape. Then press button "Crop"
      </p>
      <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={1} style={{width:'100%'}}>
      <img ref={imageRef} className={styles.result__img} src={props.images[0].src} alt="result" />
      </ReactCrop>
      <div className={styles.result__buttons}>
         <span className={styles.result__empty}></span>
         <button onClick={cropHandler} className={`${styles['canvas__button-item']}`}>Crop</button>
      </div>
      {/* <p className={styles.imagegenerate__text}>By uploading an image you hereby to our <Link className={styles['imagegenerate__text-link']} to="/terms-of-service">Terms of service.</Link> This site is protected by re CAPTCHA and the Google <a href="https://google.com" className={styles['imagegenerate__text-link']}>Privacy Policy</a> and <a href="https://google.com" className={styles['imagegenerate__text-link']}>Terms of Service</a>  apply.</p> */}
   </div>
}

export default ImageGenerateCrop