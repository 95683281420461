import { useNavigate } from 'react-router-dom';
import GradientButton from '../../UI/GradientButton/GradientButton'
import styles from './PriceItem.module.css'

const PriceItem = props => {

   const navigate = useNavigate();

   const feature = props.feature.map((item, index) => <li className={styles['item__feature-item']} key={index}>
      <span className={styles['item__feature-text']}>{item}</span>
   </li>)

   const purchaseHandler = e => {
      navigate(`/purchase/${props.planName.replace(' ', '-').toLowerCase()}`);
   }

   return <div className={`${styles.item} ${props.popular ? styles.popular : ''}`}>
      <span className={styles.item__plan}>{props.planName}</span>
      <span className={styles.item__price}>${props.price.toFixed(2)}/mo</span>
      <span className={styles.item__priceforimg}>${props.priceForImg.toFixed(2)}/Image</span>
      <ul className={styles.item__feature}>
         {feature}
      </ul>
      <GradientButton onClick={purchaseHandler} className={styles.item__btn} text="purchase"/>
   </div>
}

export default PriceItem