import styles from './TextGenerateLoader.module.css'
import loaderImg from '../../../assets/img/loader.png'

const TextGenerateLoader = props => {
   return <div className={styles.loader__inner}>
      <img src={loaderImg} alt="loader" className={styles.loader__img} />
      <p className={styles.loader__text}>Generating now… <br />Please be patient, this may take a moment.</p>
   </div>
}

export default TextGenerateLoader