import styles from './SavedGallery.module.css'

const NudifyGallery = props => {
   const images = [
      'https://picsum.photos/502/511',
      'https://picsum.photos/507/512',
      'https://picsum.photos/512/512',
      'https://picsum.photos/512/512',
      'https://picsum.photos/512/512',
      'https://picsum.photos/512/512',
      'https://picsum.photos/512/512',
   ]

   const galleryItems = images.map((item, index) => <div key={index} className={styles['gallery__item-wrapper']}>
   <img className={styles.gallery__item} src={item} />
</div>)

   return <div className={styles.gallery}>
      {galleryItems}
   </div>
}

export default NudifyGallery