import GradientButton from '../../UI/GradientButton/GradientButton'
import styles from './TextGenerate.module.css'

import searchIcon from '../../../assets/img/searchIcon.png'
import { useEffect, useRef, useState } from 'react'
import ModalTextGenerate from '../ModalTextGenerate/ModalTextGenerate'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextGenerateLoader from '../TextGenerateLoader/TextGenerateLoader'
import { CSSTransition } from 'react-transition-group'
import { generate } from '../../../api/api'

const TextGenerate = props => {

   const [isResultModalOpen, setIsResultModalOpen] = useState(false)
   const [isLoaderShow, setIsLoaderShow] = useState(false)
   const [textRequest, setTextRequest] = useState('')
   const [imageBase64, setImageBase64] = useState('')
   const [imageSeed, setImageSeed] = useState(0)

   const inputRef = useRef()

   const emptyInputError = () => {
      toast.error('Prompt can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const inputError = () => {
      toast.error('Ops.. Something is wrong', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const generateFromText = (valueArg = inputRef.current.value) => {
      const value = valueArg
      inputRef.current.value = ''
      setIsLoaderShow(true)
      setTextRequest(value)
      return new Promise((resolve, reject) => {
         const seed = Math.floor(100000 + Math.random() * 900000)
         generate.fromText(value, seed).then(data => {
            console.log(data)
            setIsLoaderShow(false)
            setImageBase64(`data:image/jpg;base64,${data.image1}`)
            setIsResultModalOpen(true)
            setImageSeed(seed)
            resolve()
         }).catch(err => {
            inputError()
            setIsLoaderShow(false)
            setIsResultModalOpen(false)
            console.log(err)
         })
      })
   }
   

   const formSubmitHandler = e => {
      e.preventDefault()
      if (!inputRef.current.value.trim()) {
         emptyInputError()
         return
      }

      generateFromText().then()


      // setTimeout(() => {
      //    setIsLoaderShow(false)
      //    setIsResultModalOpen(true)
      //    inputRef.current.value = ''
      // }, 3000)
   }

   return <>
   <ModalTextGenerate generateFromText={generateFromText} imageSeed={imageSeed} imageBase64={imageBase64} textRequest={textRequest} setModalAuthOpen={setIsResultModalOpen} stateValue={isResultModalOpen} timeout={500} authState={props.authState} />
   <form className={styles.input__inner} onSubmit={formSubmitHandler}>
      <div className={styles.input__left} onClick={() => inputRef.current.focus()}>
         <img src={searchIcon} alt="search" className={styles.input__icon} />
         <input ref={inputRef} className={styles.input__input} type="text" placeholder='Describe what you want to see' />
      </div>
      <GradientButton text="Generate" className={styles.input__button}/>
   </form>
   <CSSTransition
      unmountOnExit
      mountOnEnter
      in={isLoaderShow}
      timeout={500}
      classNames='loaderfrompromt__animation'
   >
   <TextGenerateLoader />
   </CSSTransition>
   </>
}

export default TextGenerate