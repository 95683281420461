import axios from "axios";

const instance = axios.create({
   baseURL: 'http://185.145.129.126:80/',
});

export const auth = {
   register(email, password) {
      return instance.post('reg', {
         password,
         email
      })
      .then(data => {
         return data.data
      })
   },
   login(email, password) {
      return instance.post('login', {
         password,
         email
      })
      .then(data => {
         return data.data
      })
   }  
}

export const generate = {
   fromText(prompt, seed) {
      return new Promise((resolve, reject) => {
         instance.post('inference', {
            prompt,
            seed,
            batch_size: 4,
            type: "from_text",
         })
         .then(data => resolve(data.data))
         .catch(err => reject(err))
      })
   },
   fromImage(image, mask) {
      return new Promise((resolve, reject) => {
         instance.post('inference_inpainting', {
            seed: Math.floor(100000 + Math.random() * 900000),
            batch_size: 4,
            input_image: image.split(',')[1],
            mask: mask.split(',')[1],
            prompt: "naked girl"
         })
         .then(data => resolve(data.data))
         .catch(err => reject(err))
      })
   } 
}

export const payment = {
   paypal(title, email, value, token) {
      return new Promise((resolve, reject) => {
         instance.post('create_payment', {
            title,
            gateway: "PayPal",
            email,
            value: +value,
            currency: "USD",
            return_url: 'http://185.145.129.126:80',
         },
         {
            headers: {
               'Authorization': `Bearer ${token}`
            }
         }
         )
         .then(data => resolve(data.data))
         .catch(err => reject(err))
      })
   } 
}