import { useEffect, useRef, useState } from 'react';
import CanvasDraw from "react-canvas-draw";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generate } from '../../../api/api';
import RangeInput from '../../UI/RangeInput/RangeInput';
import styles from './ImageGeneratePreview.module.css';

const ImageGeneratePreview = ({ images, setIsUploadStep, setProgressLoader, progressLoader, setServerResponse}) => {

   const innerRef = useRef()
   const canvasRef = useRef()
   const [canvasHeight, setCanvasHeight] = useState()
   const [brushSize, setBrushSize] = useState([20])

   useEffect(() => {
      setCanvasHeight((images[0].height * innerRef.current.clientWidth) / images[0].width)
   }, [])

   const inputError = () => {
      toast.error('Ops.. Something is wrong', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const undressHandler = e => {
      e.preventDefault()
      setIsUploadStep('loader')
      function timeout() {
         setTimeout(() => {
            if (progressLoader < 100) {
               timeout()
               setProgressLoader(++progressLoader)
            } else {
               return
            }
         }, 50)
      }
      timeout()

      generate.fromImage(images[0].src, canvasRef.current.getDataURL()).then(data => {
         setProgressLoader(0)
         setIsUploadStep('final')
         setServerResponse(`data:image/jpg;base64,${data.image0}`)

      }).catch(err => {
         inputError()
         console.log(err)
         setIsUploadStep('dropzone')
      })
   }

   const cancelHandler = e => {
      setIsUploadStep('dropzone')
   }

   return (
      <div ref={innerRef} className={styles.canvas__inner}>

         <div className={styles.canvas__text}>
            <p className={styles.canvas__instruction}>Please mark up nudification zone</p>
            <a href="https://youtube.com" className={styles.canvas__link}>Click here for video tutorial</a>
         </div>

         <RangeInput brushSize={brushSize} setBrushSize={setBrushSize} />
         <CanvasDraw ref={canvasRef} brushRadius={brushSize[0]} catenaryColor="transparent" brushColor="white" imgSrc={images[0].src} canvasWidth="500" canvasHeight={canvasHeight} hideGrid={true} style={
            {maxWidth: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: 'transparent'}
         }/>
         <div className={styles.canvas__button}>
            <button onClick={cancelHandler} className={`${styles['canvas__button-item']} ${styles['canvas__button-item--cancel']}`}>Cancel</button>
            <button onClick={undressHandler} className={`${styles['canvas__button-item']} ${styles['canvas__button-item--undress']}`}>Process</button>
         </div>

         <p className={styles.imagegenerate__text}>By uploading an image you hereby to our <Link className={styles['imagegenerate__text-link']} to="/terms-of-service">Terms of service.</Link> This site is protected by re CAPTCHA and the Google <a href="https://google.com" className={styles['imagegenerate__text-link']}>Privacy Policy</a> and <a href="https://google.com" className={styles['imagegenerate__text-link']}>Terms of Service</a>  apply.</p>
      </div>
    );
}

export default ImageGeneratePreview