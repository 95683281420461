import styles from './ImageGenerateResult.module.css'

import { Link } from 'react-router-dom'
import downloadIcon from '../../../assets/img/downloadIconBlack.png'

const ImageGenerateResult = props => {


   const uploadAnother = e => {
      props.setIsUploadStep('dropzone')
   }

   return <div className={styles.result}>
      <p className={styles.result__text}>
         Done. <button onClick={uploadAnother} className={styles.result__link}>Upload Another?</button>
      </p>
      <img className={styles.result__img} src={props.resultSrc} alt="result" />
      <div className={styles.result__buttons}>
         <span className={styles.result__empty}></span>
         <button onClick={uploadAnother} className={`${styles['canvas__button-item']}`}>Upload Another</button>
         <div className={styles.result__saves}>
            <button className={styles.result__save}>Save</button>
            <a href={props.resultSrc} className={styles.result__download} download={`naked.jpg`}><img src={downloadIcon} alt="download" /></a>
         </div>
      </div>
      <p className={styles.imagegenerate__text}>By uploading an image you hereby to our <Link className={styles['imagegenerate__text-link']} to="/terms-of-service">Terms of service.</Link> This site is protected by re CAPTCHA and the Google <a href="https://google.com" className={styles['imagegenerate__text-link']}>Privacy Policy</a> and <a href="https://google.com" className={styles['imagegenerate__text-link']}>Terms of Service</a>  apply.</p>
   </div>
}

export default ImageGenerateResult