import styles from './RangeInput.module.css'
import { Range } from 'react-range';
import React, { useState } from 'react';

class RangeInput extends React.Component {
   state = { values: [this.props.brushSize] };
   render() {
     return (
      <div className={styles.range__inner}>
         <Range
            step={0.1}
            min={1}
            max={50}
            values={this.state.values}
            onChange={(values) => {
               console.log(values)
               this.props.setBrushSize(values)
               this.setState({ values })
            }}
            renderTrack={({ props, children }) => (
            <div
               {...props}
               style={{
                  ...props.style,
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#DB2828'
               }}
            >
               {children}
            </div>
            )}
            renderThumb={({ props }) => (
            <div
               {...props}
               style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  border: '1px solid #000000',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '50%',
                  backgroundColor: '#fff'
               }}
            />
            )}
         />
       </div>
     );
   }
 }

export default RangeInput
